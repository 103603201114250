import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-content mx-auto" }

import HomeBanner from "@/components/HomeBanner.vue";
import PageContent from "../components/PageContent.vue";
import ContactBox from "@/components/ContactBox.vue";
import { ContactList } from "@/interfaces/Contact";
import Page from "@/interfaces/Page";
import { pages } from "@/pages";
import { onMounted, onUpdated, ref, Ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  props: {
    banner: {},
    contactList: {}
  },
  setup(__props: any) {



const homePage: Ref<Page> = ref({ title: "", content: "", active: true });

onMounted(() => {
  const home = pages.value.find((page) => page.title === "Etusivu");
  if (home) homePage.value = home;
});

onUpdated(() => {
  const home = pages.value.find((page) => page.title === "Etusivu");
  if (home) homePage.value = home;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(HomeBanner, {
      title: _ctx.banner.title,
      content: _ctx.banner.content,
      imgSrc: _ctx.banner.imgSrc
    }, null, 8, ["title", "content", "imgSrc"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(PageContent, { page: homePage.value }, null, 8, ["page"]),
      _createVNode(ContactBox, { contactList: _ctx.contactList }, null, 8, ["contactList"])
    ])
  ]))
}
}

})