import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-10 bg-footer h-96 w-full" }
const _hoisted_2 = { class: "max-w-content h-full mx-auto pl-6 flex flex-column justify-center text-white text-base" }
const _hoisted_3 = { class: "text-white mb-2.5 text-2xl font-black" }
const _hoisted_4 = ["innerHTML"]


export default /*@__PURE__*/_defineComponent({
  __name: 'PageFooter',
  props: {
    title: {},
    content: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", { innerHTML: _ctx.content }, null, 8, _hoisted_4)
    ])
  ]))
}
}

})