import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-beige" }
const _hoisted_2 = { class: "lg:w-content mx-auto flex h-72 md:h-96" }
const _hoisted_3 = { class: "lg:w-7/12 md:w-2/3 pl-4 pb-4 flex flex-column justify-center" }
const _hoisted_4 = { class: "text-3xl lg:text-4xl text-title mb-2 lg:pb-1" }
const _hoisted_5 = ["innerHTML"]

interface Banner {
  title: string;
  content: string;
  imgSrc?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeBanner',
  props: {
    title: {},
    content: {},
    imgSrc: {}
  },
  setup(__props: any) {

const props = __props;

const getBackground = () => {
  if (props.imgSrc) {
    return {
      "background-image": `url(https:${props.imgSrc})`,
    };
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "hidden md:block lg:w-5/12 md:w-1/2 -ml-24 -mt-4",
        style: _normalizeStyle(getBackground())
      }, null, 4),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", {
          class: "text-lg leading-6 sm:leading-7",
          innerHTML: _ctx.content
        }, null, 8, _hoisted_5)
      ])
    ])
  ]))
}
}

})