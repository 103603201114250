import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lg:w-content w-screen mx-auto py-5 px-2 mt-4" }

import Email from "@/interfaces/Email";
import { ref, Ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactView',
  emits: ["contact"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const form: Ref<any> = ref(null);
const email: Ref<Email> = ref({
  senderName: "",
  senderEmail: "",
  senderPhone: "",
  message: "",
});
const valid: Ref<boolean> = ref(false);
const required = [(v: string) => !!v || ""];
const snackbar: Ref<boolean> = ref(false);
const snackbarColor: Ref<string> = ref("");
const snackbarIcon: Ref<string> = ref("");
const snackbarText: Ref<string> = ref("");

const validate = () => {
  form.value.validate();
  if (!valid.value) {
    return;
  }
  emit("contact", email.value, createSnackbar);
};

const createSnackbar = (emailSent: boolean) => {
  console.log(emailSent);
  if (emailSent) {
    form.value.reset();
  }
  snackbarColor.value = emailSent ? "success" : "error";
  snackbarIcon.value = emailSent ? "mdi-check-circle" : "mdi-alert-circle";
  snackbarText.value = emailSent
    ? "Viestin lähetys onnistui"
    : "Viestin lähetys epäonnistui";
  snackbar.value = true;
};

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_snackbar, {
      location: "top",
      modelValue: snackbar.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((snackbar).value = $event)),
      id: "snackbar",
      color: snackbarColor.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(snackbarIcon.value), 1)
          ]),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(snackbarText.value), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "color"]),
    _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Ota yhteyttä", -1)),
    _createVNode(_component_v_form, {
      modelValue: valid.value,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((valid).value = $event)),
      ref_key: "form",
      ref: form
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_text_field, {
          modelValue: email.value.senderName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((email.value.senderName) = $event)),
          class: "pt-2",
          label: "Nimi",
          rules: required,
          density: "comfortable",
          variant: "outlined",
          required: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_text_field, {
          modelValue: email.value.senderEmail,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((email.value.senderEmail) = $event)),
          label: "Sähköposti",
          rules: required,
          variant: "outlined",
          density: "comfortable"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_text_field, {
          modelValue: email.value.senderPhone,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((email.value.senderPhone) = $event)),
          label: "Puhelinnumero",
          rules: required,
          variant: "outlined",
          density: "comfortable"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_textarea, {
          modelValue: email.value.message,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((email.value.message) = $event)),
          label: "Viesti",
          rules: required,
          variant: "outlined",
          density: "comfortable"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_btn, {
          class: "button",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (validate()))
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Lähetä")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})