import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onBeforeMount, Ref, ref } from "vue";
import axios from "axios";
import TopBar from "./components/TopBar.vue";
import PageFooter from "./components/PageFooter.vue";
import { ContactList } from "./interfaces/Contact";
import Email from "./interfaces/Email";
import {
  documentToHtmlString,
  Options,
} from "@contentful/rich-text-html-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";
import { pages, previewPage } from "./pages";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();

const footer: Ref<{ title: string; content: string }> = ref({
  title: "",
  content: "",
});
const banner: Ref<{
  title: string;
  content: string;
  imgSrc?: string;
}> = ref({ title: "", content: "" });
const contacts: Ref<ContactList> = ref({ title: "", contacts: [] });

const footerOptions: Partial<Options> = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, next) =>
      `<a href=${node.data.uri} class="text-white">${next(node.content)}</a>`,
    [INLINES.ASSET_HYPERLINK]: (node, next) =>
      `<a href=https://${
        node.data.target.fields.file.url
      } class="text-white">${next(node.content)}</a>`,
  },
};

const pageOptions: Partial<Options> = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) =>
      `<p class="pb-4">${next(node.content)}</p>`,
    [BLOCKS.LIST_ITEM]: (node, next) =>
      `<li class="-mb-2">${next(node.content)}</li>`,
    [BLOCKS.OL_LIST]: (node, next) =>
      `<ol class="pb-2">${next(node.content)}</ol>`,
    [BLOCKS.UL_LIST]: (node, next) =>
      `<ul class="pb-2">${next(node.content)}</ul>`,
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        target: { fields },
      },
    }) =>
      `<img src="${fields.file.url}" class="embedded-image" alt="${fields.description}"/>`,
    [INLINES.ASSET_HYPERLINK]: (node, next) =>
      `<a href=https://${node.data.target.fields.file.url}>${next(
        node.content
      )}</a>`,
  },
};

onBeforeMount(() => {
  const path = router.currentRoute;
  if (window.location.pathname.startsWith("/preview")) {
    axios.get(`backend${window.location.pathname}`).then((response) => {
      populateElements(response.data);
      const page = response.data.pages[0];
      previewPage.value = {
        title: page.title,
        content: documentToHtmlString(page.content, pageOptions),
        active: true,
      };
    });
  } else {
    axios.get("backend/").then((response) => {
      if (response.status === 200) {
        populateElements(response.data);
        pages.value = response.data.pages
          .map((page: { title: string; content: Document }) => {
            return {
              title: page.title,
              content: documentToHtmlString(page.content, pageOptions),
              active:
                (path.value.path === "/" && page.title === "Etusivu") ||
                decodeURI(path.value.path) === `/${page.title.toLowerCase()}`,
            };
          })
          .concat({
            title: "Ota yhteyttä",
            content: "",
            active: path.value.path === "/ota-yhteytta",
          });
      } else if (response.data.errorMessage) {
        console.error(response.data.errorMessage);
      }
    });
  }
});

const populateElements = (data: any) => {
  footer.value.title = data.footer.title;
  footer.value.content = documentToHtmlString(
    data.footer.content,
    footerOptions
  ).replaceAll("<p></p>", "<br />");
  banner.value = {
    title: data.banner.title,
    content: documentToHtmlString(data.banner.content),
    imgSrc: data.banner.imgSrc,
  };
  contacts.value = data.contactList;
};

const contact = (email: Email, callbackFn: (success: boolean) => void) => {
  axios
    .post("backend/contact", {
      email: { ...email, message: email.message.replaceAll("\n", "<br>") },
    })
    .then(() => {
      callbackFn(true);
    })
    .catch((error) => {
      console.error(error.message);
      callbackFn(false);
    });
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, { class: "flex flex-column min-h-screen" }, {
        default: _withCtx(() => [
          _createVNode(TopBar),
          _createVNode(_component_router_view, {
            banner: banner.value,
            contactList: contacts.value,
            onContact: contact,
            class: "flex-1"
          }, null, 8, ["banner", "contactList"]),
          _createVNode(PageFooter, {
            title: footer.value.title,
            content: footer.value.content
          }, null, 8, ["title", "content"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})