import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-content mx-auto" }

import { pages } from "@/pages";
import Page from "@/interfaces/Page";
import PageContent from "../components/PageContent.vue";
import { Ref, ref, onMounted, onBeforeUpdate } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PageView',
  setup(__props) {

const page: Ref<Page> = ref({ title: "", content: "", active: false });

onMounted(() => {
  const activePage = pages.value.find((page) => page.active);
  if (activePage) page.value = activePage;
});

onBeforeUpdate(() => {
  const activePage = pages.value.find((page) => page.active);
  if (activePage) page.value = activePage;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PageContent, { page: page.value }, null, 8, ["page"])
  ]))
}
}

})