import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-3 shadow-md z-50",
  style: {"height":"110px"}
}
const _hoisted_2 = {
  class: "max-w-content mx-auto pt-2",
  style: {"height":"60px"}
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex max-w-content mx-auto" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["href"]

import { pages } from "@/pages";
import { ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

const baseClass =
  "px-2 border-b-4 rounded flex place-items-center cursor-pointer lg:hover:border-hover";

export default /*@__PURE__*/_defineComponent({
  __name: 'TopBar',
  setup(__props) {

const router = useRouter();
const { mobile } = useDisplay();
const drawer: Ref<boolean> = ref(false);

const activeClass = baseClass + " border-main";
const inactiveClass = baseClass + " border-white";
const intranetUrl = process.env.VUE_APP_INTRA_URL;

const navigate = (url: string) => {
  if (url === "Etusivu") {
    router.push("/");
  } else if (url === "Ota yhteyttä") {
    router.push("/ota-yhteytta");
  } else {
    router.push(`/${url}`.toLowerCase());
  }
};

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(mobile))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: require('../assets/logo300.png'),
              alt: "Terveyden Tuottajat logo",
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (navigate('Etusivu')))
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pages), (page) => {
              return (_openBlock(), _createElementBlock("div", {
                key: page.title,
                class: _normalizeClass(page.active ? activeClass : inactiveClass),
                style: {"height":"50px"},
                onClick: ($event: any) => (navigate(page.title))
              }, _toDisplayString(page.title), 11, _hoisted_5))
            }), 128)),
            _createElementVNode("a", {
              href: _unref(intranetUrl),
              class: "ml-auto mr-4",
              target: "_blank"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(inactiveClass),
                style: {"height":"50px","margin-left":"auto"}
              }, [
                _cache[5] || (_cache[5] = _createTextVNode(" Intranet ")),
                _createVNode(_component_v_icon, { class: "icon ml-2 text-gray-800" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" mdi-open-in-new ")
                  ])),
                  _: 1
                })
              ])
            ], 8, _hoisted_6)
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(mobile))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_v_app_bar, { style: {"box-shadow":"0 4px 6px -1px rgb(0 0 0 / 0.1)"} }, {
            default: _withCtx(() => [
              _createVNode(_component_v_app_bar_nav_icon, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (drawer.value = !drawer.value))
              }),
              _createElementVNode("img", {
                src: require('../assets/logo300.png'),
                alt: "Terveyden Tuottajat logo",
                class: "cursor-pointer scale-75 -translate-x-8",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (navigate('Etusivu')))
              }, null, 8, _hoisted_8)
            ]),
            _: 1
          }),
          _createVNode(_component_v_navigation_drawer, {
            modelValue: drawer.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((drawer).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pages), (page) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: page.title,
                      onClick: ($event: any) => (navigate(page.title))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(page.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128)),
                  _createElementVNode("a", {
                    href: _unref(intranetUrl),
                    class: "ml-auto mr-4",
                    target: "_blank"
                  }, [
                    _createVNode(_component_v_list_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _cache[7] || (_cache[7] = _createTextVNode("Intranet ")),
                            _createVNode(_component_v_icon, { class: "icon ml-2 text-gray-800" }, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createTextVNode(" mdi-open-in-new ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ], 8, _hoisted_9)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})