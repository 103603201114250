import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-2.5 contact-box" }
const _hoisted_2 = { class: "contact-title bg-main" }
const _hoisted_3 = { class: "text-white text-base" }

import { ContactList } from "@/interfaces/Contact";
import ContactInfo from "./ContactInfo.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactBox',
  props: {
    contactList: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.contactList.title), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactList.contacts, (contact) => {
      return (_openBlock(), _createBlock(ContactInfo, {
        key: contact.name,
        contact: contact
      }, null, 8, ["contact"]))
    }), 128))
  ]))
}
}

})