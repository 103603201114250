import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-6 py-1 bg-beige text-text" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "contact-info flex flex-col md:flex-row text-lg" }
const _hoisted_4 = {
  class: "flex items-center w-2/5",
  style: {"min-width":"320px"}
}
const _hoisted_5 = { href: "mailto:{{contact.email}}" }
const _hoisted_6 = { class: "flex items-center" }

import { Contact } from "../interfaces/Contact";

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactInfo',
  props: {
    contact: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.contact.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.contact.email), 1)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.contact.info), 1)
    ])
  ]))
}
}

})