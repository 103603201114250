import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "py-5 px-2 lg:-mx-1 mt-4" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 0 }

import Page from "@/interfaces/Page";


export default /*@__PURE__*/_defineComponent({
  __name: 'PageContent',
  props: {
    page: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      innerHTML: _ctx.page.content
    }, null, 8, _hoisted_2),
    (!_ctx.page.content.length && !_ctx.page.active)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "text-2xl font-bold text-title mr-3" }, "404", -1),
          _createTextVNode("Sivua ei löydy. Tarkista osoite. ")
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})