import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "max-w-content mx-auto"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "max-w-content mx-auto" }

import { previewPage } from "@/pages";
import Page from "@/interfaces/Page";
import HomeBanner from "@/components/HomeBanner.vue";
import PageContent from "../components/PageContent.vue";
import ContactBox from "@/components/ContactBox.vue";
import { Ref, ref, onBeforeUpdate } from "vue";
import { ContactList } from "@/interfaces/Contact";


export default /*@__PURE__*/_defineComponent({
  __name: 'PagePreview',
  props: {
    banner: {},
    contactList: {}
  },
  setup(__props: any) {



const page: Ref<Page> = ref({ title: "", content: "", active: true });

onBeforeUpdate(() => {
  page.value = previewPage.value;
});

return (_ctx: any,_cache: any) => {
  return (page.value.title !== 'Etusivu')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(PageContent, { page: page.value }, null, 8, ["page"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(HomeBanner, {
          title: _ctx.banner.title,
          content: _ctx.banner.content,
          imgSrc: _ctx.banner.imgSrc
        }, null, 8, ["title", "content", "imgSrc"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(PageContent, { page: page.value }, null, 8, ["page"]),
          _createVNode(ContactBox, { contactList: _ctx.contactList }, null, 8, ["contactList"])
        ])
      ]))
}
}

})